<template>
  <div>
    <general-table
      ref="userTable"
      :api-url="APIURL"
      :add-type="addType"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :view-content="false"
      :export-table="false"
      :type="type"
      :columns="columns"
      :guard="guard"
      :search-box="false"
      :selectable="false"
    >
      <template #cell(image)="data">

        <img
          :src="data.item.image"
          width="100"
        >
      </template>
      <template
        v-slot:cell(description)="data"
      >
        <div class="desTrim">
          {{ data.item.description }}
        </div>
      </template>
      <template
        v-slot:cell(description_ar)="data"
      >
        <div class="desTrim">
          {{ data.item.description_ar }}
        </div>
      </template>
    </general-table>

  </div>
</template>

<script>

import GeneralTable from '@/components/general-table/GeneralTable.vue'
import handleFormData from '@/global-compositions/handleFormData'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'gaming-categories',
      addType: 'Add Category',
      addComponentName: 'add-gaming-category',
      editComponent: 'edit-gaming-category',
      type: 'page',
      guard: 'gaming_category',
      columns: [

        { key: 'id', sortable: true },
        { key: 'name' },
        { key: 'image' },
        { key: 'description' },
        { key: 'description_ar', label: 'Arabic Description' },
        { key: 'actions' },
      ],

      loader: false,
      userForm: {},
      types: {},
      currentTime: '',
      role: '',
    }
  },
  setup() {
    const { formData, setFormData } = handleFormData()

    return {
      formData, setFormData,
    }
  },
  created() {

  },
  methods: {

    filterTypes(type) {
      const filteredType = this.activeType.filter(item => item.text !== type)
      this.types = filteredType
    },

  },
}
</script>

<style scoped>
.change-status {
  cursor: pointer;
}
</style>
